import React from 'react'
import { graphql, Link } from 'gatsby'
import Seo from '../components/seo'
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';
import _ from 'lodash'

const ArchivePage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  const recentPosts = data.recentPosts?.edges || [];

  // Group posts by year
  const postsByYear = _.groupBy(posts, ({ node }) => {
    return node.frontmatter.rawDate.substring(0, 4)
  })

  // Sort years in descending order
  const years = Object.keys(postsByYear).sort((a, b) => b - a)

  return (
    <>
      <Navigation />
      <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <main className="lg:col-span-8 lg:col-start-1">
              <h2 className="text-4xl text-gray-900 mb-12">Archive</h2>
              <div className="space-y-16">
                {years.map(year => (
                  <div key={year}>
                    <h3 className="text-2xl text-brand-600 mb-6">{year}</h3>
                    <ul className="space-y-4">
                      {postsByYear[year].map(({ node }) => (
                        <li key={node.fields.slug} className="border-b border-gray-100 pb-4 last:border-b-0">
                          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-baseline">
                            <Link 
                              to={node.fields.slug} 
                              className="text-gray-900 hover:text-brand-600"
                            >
                              {node.frontmatter.title}
                            </Link>
                            <time className="text-sm text-gray-600 mt-1 sm:mt-0">
                              {node.frontmatter.date}
                            </time>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </main>

            {/* Sidebar */}
            <aside className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-8 space-y-8">
                {/* Recent Posts */}
                <div className="border-l-4 border-brand-200 pl-4">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">Latest Writings</h2>
                  <div className="space-y-3">
                    {recentPosts.map(({ node }) => (
                      <Link 
                        key={node.fields.slug}
                        to={node.fields.slug}
                        className="block text-gray-700 hover:text-brand-600"
                      >
                        {node.frontmatter.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ArchivePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            rawDate: date
            title
          }
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
